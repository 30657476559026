.form-switch .form-check-input {
    position: relative;
    background-color: #ced4da;
    height: 1.5rem;
    width: 2.8rem;
    cursor: pointer;
  }
  
  .form-switch .form-check-input:checked:after {
    transform: translateX(21px);
    border-color: #42424a;
  }
  
  .form-switch .form-check-input:checked {
    border-color: #42424a;
    background-color: #7239ea !important;
    cursor: pointer;
  }
  
  .form-switch .form-check-input:checked[type='checkbox']::after {
    background-color: #982fd4 !important;
    color: #fff;
    content: 'ON';
    font-size: 9px;
    font-weight: 800;
    text-align: center;
    padding-top: 3.5px;
  }
  
  .form-switch .form-check-input:after {
    transition: transform 0.25s ease-in-out, background-color 0.25s ease-in-out;
    content: '';
    width: 1.85rem;
    height: 1.85rem;
    border-radius: 50%;
    border: 1px solid #ced4da;
    position: absolute;
    background-color: #fff;
    transform: translateX(1px);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    top: 0px;
    left: 1px;
  }
  
  .form-switch.label-switch :after {
    transition: transform 0.25s ease-in-out, background-color 0.25s ease-in-out;
    content: '';
    width: 1.55rem;
    height: 1.55rem;
    border-radius: 50%;
    border: 1px solid #ced4da;
    position: absolute;
    background-color: #fff;
    transform: translateX(1px);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    top: -1.5px;
    left: -5px;
  }
  
  .form-switch.label-switch .form-check-input:checked[type='checkbox']::after {
    background-color: #982fd4 !important;
    color: #fff;
    content: 'ON';
    font-size: 7px;
    font-weight: 800;
    text-align: center;
    padding-top: 3.5px;
  }
  
  .tooltip-inner {
    max-width: var(--bs-tooltip-max-width);
    padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
    color: white;
    text-align: center;
    background-color: black;
    border-radius: var(--bs-tooltip-border-radius);
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width)* 0.5) 0;
  border-top-color: black !important;
}

.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width)* 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width)* 0.5) 0;
  border-right-color: black !important;
}

.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width)* 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width)* 0.5) 0;
  border-left-color: black !important;
}

.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width)* 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width)* 0.5) 0;
  border-bottom-color: black !important;
}
